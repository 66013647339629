import React from "react";
import moment from "moment";
import { IDStatus, IDResult } from "../../commons";
import "./ResultCard.css";

export interface Props {
  visible: boolean;
  loading: boolean;
  result: IDResult;
  onManualCheckPressed: () => void;
}

const ResultCard = (props: Props) => {
  const { visible, loading, result, onManualCheckPressed } = props;
  const {
    status,
    securityLicense,
    securityLicenseExpiresAt: expiresAt,
    orgName
  } = result;

  const expiresAtFormatted = expiresAt
    ? "Expires " + moment(expiresAt.seconds).format("MM/DD/YYYY")
    : "";

  return (
    <div style={{ display: visible ? "block" : "none" }}>
      <div className="brand">
        <a className="brand-link" href="/">
          <img
            alt="logo"
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMjkiIGhlaWdodD0iMzU4IiB2aWV3Qm94PSIwIDAgMzI5IDM1OCI+CiAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSguNCkiPgogICAgPHBhdGggZmlsbD0iIzI2NDRDRSIgZD0iTTIyOC42LDEwIEMyMjguNiw0LjQ3NzE1MjUgMjMzLjA3NzE1MywtMi41MzgxODMwNWUtMTUgMjM4LjYsMCBMMjQ4LjYsMCBDMjU0LjEyMjg0NywtMS4wMTQ1MzA2M2UtMTUgMjU4LjYsNC40NzcxNTI1IDI1OC42LDEwIEwyNTguNiwzMCBMMjg0LDMwIEMzMDguMzAwNTI5LDMwIDMyOCw0OS42OTk0NzEgMzI4LDc0IEwzMjgsMzE0IEMzMjgsMzM4LjMwMDUyOSAzMDguMzAwNTI5LDM1OCAyODQsMzU4IEw0NCwzNTggQzE5LjY5OTQ3MSwzNTggMi45NzU5NTY1ZS0xNSwzMzguMzAwNTI5IDAsMzE0IEwwLDc0IEMtMi45NzU5NTY1ZS0xNSw0OS42OTk0NzEgMTkuNjk5NDcxLDMwIDQ0LDMwIEw2Ni42LDMwIEw2Ni42LDEwIEM2Ni42LDQuNDc3MTUyNSA3MS4wNzcxNTI1LC0yLjUzODE4MzA1ZS0xNSA3Ni42LDAgTDg2LjYsMCBDOTIuMTIyODQ3NSwtMS4wMTQ1MzA2M2UtMTUgOTYuNiw0LjQ3NzE1MjUgOTYuNiwxMCBMOTYuNiwzMCBMMjI4LjYsMzAgTDIyOC42LDEwIFoiLz4KICAgIDxwYXRoIGZpbGw9IiMxRTc3RTYiIGQ9Ik0wLDc0IEwzMjgsNzQgTDMyOCwzMTQgQzMyOCwzMzguMzAwNTI5IDMwOC4zMDA1MjksMzU4IDI4NCwzNTggTDQ0LDM1OCBDMTkuNjk5NDcxLDM1OCAyLjk3NTk1NjVlLTE1LDMzOC4zMDA1MjkgMCwzMTQgTDAsNzQgTDAsNzQgWiIvPgogICAgPHBvbHlnb24gZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIiBwb2ludHM9IjEzMS4yMjMgMjk5IDEzMS4yMjMgMTc2Ljc4NiA4OCAxNzYuNzg2IDg4IDEzMyAyNDAgMTMzIDI0MCAxNzYuNzg2IDE5Ny40OTggMTc2Ljc4NiAxOTcuNDk4IDI5OSIvPgogIDwvZz4KPC9zdmc+Cg=="
            className="layout-logo"
          />
          <span className="layout-title">TIMETRI</span>
        </a>
      </div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <div className="Status h07_h1">
            {status === IDStatus.Verified ? "Verified" : "Not Verified"}
          </div>
          <div style={{ margin: 40 }}>
            <div className="LicenseNumber">{securityLicense}</div>
            <div>{expiresAtFormatted}</div>
          </div>
          <div>{orgName}</div>
          <button
            className="ManualCheck h07_text"
            onClick={onManualCheckPressed}
          >
            Manually enter and run ID check?
          </button>
        </div>
      )}
    </div>
  );
};

export default ResultCard;
