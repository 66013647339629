import React, { useState, useCallback, FormEvent } from "react";
import Field from "./Field";

export interface Props {
  visible: boolean;
  onRunCheckPressed: (employeeLicense: string, agencyLicense: string) => void;
}

const ManualCheck = (props: Props) => {
  const [employeeLicense, setEmployeeLicense] = useState("");
  const [agencyLicense, setAgencyLicense] = useState("");
  const [employeeError, setEmployeeError] = useState("");
  const [agencyError, setAgencyError] = useState("");

  const { visible, onRunCheckPressed } = props;

  const onEmployeeChange = useCallback((newValue: string) => {
    setEmployeeLicense(newValue);
    setEmployeeError("");
  }, []);

  const onAgencyChange = useCallback((newValue: string) => {
    setAgencyLicense(newValue);
    setAgencyError("");
  }, []);

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!employeeLicense) {
        setEmployeeError("Employee License is required");
      }
      if (!agencyLicense) {
        setAgencyError("Agency License is required");
      }

      if (employeeLicense && agencyLicense) {
        onRunCheckPressed(employeeLicense, agencyLicense);
      }
    },
    [employeeLicense, agencyLicense, onRunCheckPressed]
  );

  return (
    <div style={{ display: visible ? "block" : "none" }}>
      <div className="brand">
        <a className="brand-link" href="/">
          <img
            alt="logo"
            src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMjkiIGhlaWdodD0iMzU4IiB2aWV3Qm94PSIwIDAgMzI5IDM1OCI+CiAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSguNCkiPgogICAgPHBhdGggZmlsbD0iIzI2NDRDRSIgZD0iTTIyOC42LDEwIEMyMjguNiw0LjQ3NzE1MjUgMjMzLjA3NzE1MywtMi41MzgxODMwNWUtMTUgMjM4LjYsMCBMMjQ4LjYsMCBDMjU0LjEyMjg0NywtMS4wMTQ1MzA2M2UtMTUgMjU4LjYsNC40NzcxNTI1IDI1OC42LDEwIEwyNTguNiwzMCBMMjg0LDMwIEMzMDguMzAwNTI5LDMwIDMyOCw0OS42OTk0NzEgMzI4LDc0IEwzMjgsMzE0IEMzMjgsMzM4LjMwMDUyOSAzMDguMzAwNTI5LDM1OCAyODQsMzU4IEw0NCwzNTggQzE5LjY5OTQ3MSwzNTggMi45NzU5NTY1ZS0xNSwzMzguMzAwNTI5IDAsMzE0IEwwLDc0IEMtMi45NzU5NTY1ZS0xNSw0OS42OTk0NzEgMTkuNjk5NDcxLDMwIDQ0LDMwIEw2Ni42LDMwIEw2Ni42LDEwIEM2Ni42LDQuNDc3MTUyNSA3MS4wNzcxNTI1LC0yLjUzODE4MzA1ZS0xNSA3Ni42LDAgTDg2LjYsMCBDOTIuMTIyODQ3NSwtMS4wMTQ1MzA2M2UtMTUgOTYuNiw0LjQ3NzE1MjUgOTYuNiwxMCBMOTYuNiwzMCBMMjI4LjYsMzAgTDIyOC42LDEwIFoiLz4KICAgIDxwYXRoIGZpbGw9IiMxRTc3RTYiIGQ9Ik0wLDc0IEwzMjgsNzQgTDMyOCwzMTQgQzMyOCwzMzguMzAwNTI5IDMwOC4zMDA1MjksMzU4IDI4NCwzNTggTDQ0LDM1OCBDMTkuNjk5NDcxLDM1OCAyLjk3NTk1NjVlLTE1LDMzOC4zMDA1MjkgMCwzMTQgTDAsNzQgTDAsNzQgWiIvPgogICAgPHBvbHlnb24gZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIiBwb2ludHM9IjEzMS4yMjMgMjk5IDEzMS4yMjMgMTc2Ljc4NiA4OCAxNzYuNzg2IDg4IDEzMyAyNDAgMTMzIDI0MCAxNzYuNzg2IDE5Ny40OTggMTc2Ljc4NiAxOTcuNDk4IDI5OSIvPgogIDwvZz4KPC9zdmc+Cg=="
            className="layout-logo"
          />
          <span className="layout-title">TIMETRI</span>
        </a>
      </div>
      <div className="po2_h2">Run ID Check</div>
      <div className="h07_text">
        Manually run an ID check to verify if the ID is valid, as well as which
        organization it belongs to.
      </div>
      <form onSubmit={onSubmit}>
        <Field
          placeholder="Employee License"
          value={employeeLicense}
          error={employeeError}
          onChange={onEmployeeChange}
        />
        <Field
          placeholder="Agency License"
          value={agencyLicense}
          error={agencyError}
          onChange={onAgencyChange}
        />
        <button type="submit" className="buttonPrimary">
          Run Check
        </button>
      </form>
    </div>
  );
};

export default ManualCheck;
