import { firestore } from "firebase/app";

export enum IDStatus {
  None = "none",
  Verified = "verified",
  NotVerified = "not verified"
}

export interface IDResult {
  status: IDStatus;
  securityLicense: string;
  securityLicenseExpiresAt: firestore.Timestamp | null;
  orgName: string | null;
}

export function findUrlParam(paramName: string) {
  const rawParam = globalThis.location.search
    .substr(1)
    .split("&")
    .find(pair => pair.startsWith(paramName + "="))
    ?.substring(paramName.length + 1);

  return rawParam ? decodeURIComponent(rawParam) : null;
}
