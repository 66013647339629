import Firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

import devConfig from "./configs/dev.json";
import prodConfig from "./configs/prod.json";

const { NODE_ENV, REACT_APP_APP_ENV: APP_ENV } = process.env;

/**
 * TODO refactor this
 *
 * This is wonky but should work for now. NODE_ENV will always equal "production" whenever we deploy
 * to the staging or production environments. We use another variable (APP_ENV) to identify between
 * the two. Perhaps building a test build using npm (`npm test`) will work better here since it will
 * set NODE_ENV to "test"?
 */
const isProd = NODE_ENV === "production" && APP_ENV === "production";
const config = isProd ? prodConfig : devConfig;

const firebase = Firebase.initializeApp(config);
const db = firebase.firestore();
const functions = firebase.functions();
const storage = firebase.storage();
const { auth } = Firebase;

/**
 * TODO refactor this to check for "test" once we get the above set
 */
if (NODE_ENV !== "production") {
  db.settings({
    host: "localhost: 8088",
    ssl: false
  });

  functions.useFunctionsEmulator("http://localhost:5001");
}

export { firebase, auth, db, functions, storage };
