import React, { useEffect, useState, useCallback } from "react";
import ResultCard from "./components/ResultCard";
import ManualCheck from "./components/ManualCheck";
import { findUrlParam } from "./commons";
import { checkLicense, negativeResult } from "./firebase";
import "./App.css";

enum Screen {
  RESULT,
  MANUAL_ENTER
}

const App = () => {
  const [screen, setScreen] = useState(Screen.RESULT);
  const [result, setResult] = useState(negativeResult);
  const [loading, setLoading] = useState(false);

  const showManualForm = useCallback(() => setScreen(Screen.MANUAL_ENTER), []);

  const check = useCallback(
    (employeeLicense: string, agencyLicense: string) => {
      setLoading(true);
      checkLicense(employeeLicense, agencyLicense).then(result => {
        setResult(result);
        setLoading(false);
      });
    },
    []
  );

  const runCheck = useCallback(
    (employeeLicense: string, agencyLicense: string) => {
      setScreen(Screen.RESULT);
      check(employeeLicense, agencyLicense);
    },
    [check]
  );

  useEffect(() => {
    const employeeLicense = findUrlParam("employee");
    const agencyLicense = findUrlParam("agency");
    if (employeeLicense && agencyLicense) {
      check(employeeLicense, agencyLicense);
    }
  }, [check]);

  return (
    <div className="App">
      <ResultCard
        visible={screen === Screen.RESULT}
        loading={loading}
        result={result}
        onManualCheckPressed={showManualForm}
      />
      <ManualCheck
        visible={screen === Screen.MANUAL_ENTER}
        onRunCheckPressed={runCheck}
      />
    </div>
  );
};

export default App;
