import { functions } from "./init";
import { IDStatus, IDResult } from "../commons";

const checkLicenseCallable = functions.httpsCallable(
  "electronicID-https-checkLicense"
);

export const negativeResult: IDResult = {
  status: IDStatus.NotVerified,
  securityLicense: "",
  securityLicenseExpiresAt: null,
  orgName: null
};

export const checkLicense = async (
  employeeLicense: string,
  agencyLicense: string
): Promise<IDResult> => {
  try {
    const { data } = await checkLicenseCallable({
      employeeLicense,
      agencyLicense
    });

    return data;
  } catch (err) {
    console.log(err);
    return negativeResult;
  }
};
