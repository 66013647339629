import React, { useCallback, ChangeEvent } from "react";

export interface Props {
  placeholder: string;
  value: string;
  error: string;
  onChange: (newValue: string) => void;
}

const Field = (props: Props) => {
  const { placeholder, value, error, onChange } = props;

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  return (
    <div>
      <div>
        <input
          className="form-input"
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          autoCorrect="off"
          autoComplete="off"
        />
      </div>
      <div className="error_text">{error}</div>
    </div>
  );
};

export default Field;
